import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular-ivy";

Sentry.init({
  dsn: "https://cf4a4c64f567f627d656c677790474b8@o4507141551357952.ingest.de.sentry.io/4507141557059664",
  integrations: [
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.5,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(Sentry.captureException);
